<template>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.9941 2.09998H9.01696C4.6832 2.09998 2.09961 4.68228 2.09961 9.01388V18.9742C2.09961 23.3177 4.6832 25.9 9.01696 25.9H18.9822C23.316 25.9 25.8996 23.3177 25.8996 18.9861V9.01388C25.9115 4.68228 23.3279 2.09998 18.9941 2.09998ZM19.2084 16.7965L16.5653 19.4383C15.9224 20.0809 15.089 20.3903 14.2556 20.3903C13.4222 20.3903 12.5768 20.069 11.9458 19.4383C11.3267 18.8195 10.9814 17.9984 10.9814 17.1297C10.9814 16.261 11.3267 15.428 11.9458 14.8211L13.6246 13.1432C13.9698 12.7981 14.5413 12.7981 14.8866 13.1432C15.2319 13.4883 15.2319 14.0595 14.8866 14.4046L13.2078 16.0825C12.9221 16.3681 12.7673 16.737 12.7673 17.1297C12.7673 17.5224 12.9221 17.9032 13.2078 18.1769C13.7912 18.76 14.7318 18.76 15.3152 18.1769L17.9583 15.5351C19.4704 14.0238 19.4704 11.5724 17.9583 10.0611C16.4463 8.54977 13.9936 8.54977 12.4816 10.0611L9.60035 12.9409C8.99314 13.5478 8.65978 14.3451 8.65978 15.19C8.65978 16.0349 8.99314 16.8441 9.60035 17.4391C9.94562 17.7842 9.94562 18.3554 9.60035 18.7005C9.25508 19.0456 8.68359 19.0456 8.33832 18.7005C7.38584 17.7842 6.86198 16.5347 6.86198 15.2019C6.86198 13.8691 7.37394 12.6196 8.31451 11.6795L11.1957 8.79968C13.3983 6.59818 16.9939 6.59818 19.1965 8.79968C21.411 11.0012 21.411 14.595 19.2084 16.7965Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ClipIcon',
    };
</script>
